/*=========================================================================================
  File Name: moduleCountryMutations.js
  Description: Country Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  ADD_ITEM(state, item) {
    state.SearchKeyWords.unshift(item)
  },
  SET_SearchKeyWords(state, SearchKeyWords) {
    debugger;
    try {
    
      state.SearchKeyWords = SearchKeyWords;
     
    } catch (error) {
      console.error("Failed to parse SearchKeyWords data JSON:", error);
      state.SearchKeyWords = [];
    }
  },
  UPDATE_SearchKeyWordsd(state, SearchKeyWords) {
    debugger
    const SearchKeyWordsIndex = state.SearchKeyWords.findIndex((p) => p.Id == SearchKeyWords.Id)
    Object.assign(state.SearchKeyWords[SearchKeyWordsIndex], SearchKeyWords)
  },
  REMOVE_ITEM(state, itemId) {
      const ItemIndex = state.SearchKeyWords.findIndex((p) => p.ID == itemId)
      state.SearchKeyWords.splice(ItemIndex, 1)
  },
}
