/*=========================================================================================
  File Name: moduleCountryActions.js
  Description: Country Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from "@/axios.js"

export default {
  addItem({ commit }, item) {

    debugger;
    var Token={Authorization: 'Bearer ' + localStorage.accessToken};
    return new Promise((resolve, reject) => {
      axios.post("API/CategoryNamesForSearch/Add", item,{headers:Token})
        .then((response) => {
          commit('ADD_ITEM', Object.assign(item, { ID: response.data.data.ID }))
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  fetchDataListItems({ commit }) {
    debugger;
    var Token={Authorization: 'Bearer ' + localStorage.accessToken};
    return new Promise((resolve, reject) => {
      axios.get("api/CategoryNamesForSearch/Index",{headers:Token})
        .then((response) => {
          debugger;
          commit('SET_SearchKeyWords', response.data.Data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  updateItem({ commit }, item) {
    var Token={Authorization: 'Bearer ' + localStorage.accessToken};
    return new Promise((resolve, reject) => {
      axios.put("API/CategoryNamesForSearch/Update", item,{headers:Token})
        .then((response) => {
          commit('UPDATE_SearchKeyWords', item)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  GetItemByID({ commit }, itemid) {
    var Token={Authorization: 'Bearer ' + localStorage.accessToken};
    return new Promise((resolve, reject) => {
      axios.get("API/CategoryNamesForSearch/GetByID?Id=" + itemid,{headers:Token})
        .then((response) => {
          commit('UPDATE_SearchKeyWords', response.data.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  removeItem({ commit }, item) {
    debugger;
    var Token={Authorization: 'Bearer ' + localStorage.accessToken};
    return new Promise((resolve, reject) => {
      axios.delete("API/CategoryNamesForSearch/Delete?Id="+item.Id,{headers:Token})
        .then((response) => {
          commit('REMOVE_ITEM', item.Id)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
}
