<!-- =========================================================================================
  File Name: AddNewDataSidebar.vue
  Description: {{$t('AddNew')}} Data - Sidebar component
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <vs-sidebar position-right parent="body" default-index="1" color="primary" class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveLocal">

    <div class="mt-6 flex items-center justify-between px-6">
        <h4 style="color: white;">{{ Object.entries(this.data).length === 0 ?  $t("AddsearchK") : $t("upsearchK") }}</h4>
        <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
    </div>

    <vs-divider class="mb-0"></vs-divider>

    <VuePerfectScrollbar class="scroll-area--data-list-add-new" :settings="settings">

      <div class="p-6">
        <!-- NAME -->
        <label style="font-weight: bold;color: white;">كلمة البحث</label>
        <vs-input style="color: black;" v-model="KeyWord" class="mt-5 w-full" name="KeyWord"/>
        <span style="color: white;" class="text-danger text-sm" v-show="errors.has('KeyWord')">{{ errors.first('KeyWord') }}</span>
      </div>
      
      <div class="p-6">
        <!-- اختيار الفئة -->
        <label style="font-weight: bold;color: white;">{{  $t("Category")}} </label>
          <v-select style="color: white;"
          label="NameAr" 
            class="mt-5 w-full sclass"
            name="NameAr" 
            v-model="selectedCategory"
            :reduce="Category => Category.ID"
            :options="categories"
            
          />


        <!-- <label style="font-weight: bold;color: white;">اسم الفئة</label>
        <vs-select v-model="selectedCategory" class="mt-5 w-full" style="color: black;">
          <vs-select-item v-for="category in categories" 
                          :key="category.ID" 
                          :value="category.ID" 
                          :text="category.NameAr"/>
        </vs-select> -->
      </div>
     
      <div class="flex flex-wrap items-center p-6" >
        <vs-button class="mr-6" @click="submitData" :disabled="!isFormValid" style="background-color: aliceblue; color: black; font-weight: bold;" color="info">{{$t("Save")}}</vs-button>
        <vs-button type="border" color="danger" @click="isSidebarActiveLocal = false">{{$t("Cancel")}}</vs-button>
      </div>
      

    </VuePerfectScrollbar>


  </vs-sidebar>
</template>

<script>

import VuePerfectScrollbar from "vue-perfect-scrollbar";
import moduleAdsCatgeory from "@/store/AdsCatgeory/moduleAdsCatgeory.js";
export default {
  components: {
    VuePerfectScrollbar,
   
  },
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object,
      default: () => {}
    },

  },
  watch: {
    
  //   selectedCategory(newVal, oldVal) {
  //   if (newVal !== oldVal) {
  //     console.log("Keeping sidebar open after category selection.");
  //     this.isSidebarActiveLocal = true; // إجبار الـ sidebar على البقاء مفتوحًا
  //   }
  // },
    // handler(newData) {
    //   if (newData && newData.AdsCategoryId) {
    //   this.selectedCategory = newData.AdsCategoryId; // تعيين الفئة المختارة
    // }
    // },
    // deep: true,
    // immediate: true,
 
    isSidebarActive(val) {
      debugger
      if (!val) return;
      if (Object.entries(this.data).length === 0) {
        this.initValues();
        this.$validator.reset();
      } else {
        let { Id, KeyWord,AdsCategoryId} = JSON.parse(
          JSON.stringify(this.data)
        );
        this.Id = Id;
        this.KeyWord = KeyWord;
        this.selectedCategory =AdsCategoryId;
      }
      
    }
  },
  data() {
    return {
      
     // categories: [], // تخزين قائمة الفئات
    selectedCategory: 0, // تخزين الفئة المختارة
    
      Id: 0,
      KeyWord: "",
      //AdsCategoryId:0,
      
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.6
      }
    };
  },
  computed: {
    categories() {
      console.log("ccccccccc",this.$store.state.CategoriesList.AdsCatgeorys);
      debugger;
      return this.$store.state.CategoriesList.AdsCatgeorys;
    },
    
    isSidebarActiveLocal: {
   
    get() {
      return this.isSidebarActive;
    },
    set(val) {
      debugger;
      if (!val && this.isSidebarActive) {
        console.log("Sidebar closed manually");
        this.$emit("closeSidebar");
      }
    }
  
    },
    isFormValid() {
      debugger
      return !this.errors.any() && this.KeyWord && this.selectedCategory;
    }
  },
  methods: {
    beforeUpdate() {
  console.log("🔍 Checking if data is changing unexpectedly:", this.data);
},
updateSelectedCategory(value) {
    this.selectedCategory = value;
  },
    initValues() {
      if (this.data && this.data.ID) return;
      this.Id = 0;
      this.KeyWord = "";
     
      this.AdsCategoryId=0;
     
    },
    
    submitData() {
      debugger;
      this.$validator.validateAll().then(result => {
        if (result) {
          const CategoryNamesForSearchDTO = {
            Id: this.Id,
            KeyWord: this.KeyWord,
            AdsCategoryId: this.selectedCategory,
           
          };

          if (this.Id !== null && this.Id > 0) {
            debugger;
            this.$store.dispatch("SearchKeyWordsList/updateItem", CategoryNamesForSearchDTO).then(() => {
              debugger;
                this.$store.dispatch("SearchKeyWordsList/fetchDataListItems");
                window.showSuccess();
              })
              .catch((err) => {
                console.error(err);
              });
          } 
          else {
            delete CategoryNamesForSearchDTO.Id;
            CategoryNamesForSearchDTO.popularity = 0;
            this.$store
              .dispatch("SearchKeyWordsList/addItem", CategoryNamesForSearchDTO)
              .then(() => {
                this.$store.dispatch("SearchKeyWordsList/fetchDataListItems");
                window.showSuccess();
              })
              .catch((err) => {
                console.error(err);
              });
          }

          this.$emit("closeSidebar");
          this.initValues();
        }
      });
    },
  },  
  created() {
    if (!moduleAdsCatgeory.isRegistered) {
      this.$store.registerModule("CategoriesList", moduleAdsCatgeory);
      moduleAdsCatgeory.isRegistered = true;
    }
    this.$store.dispatch("CategoriesList/fetchDataListItems");
  },
  mounted() {
    this.isMounted = true;
  },
};
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  height: calc(100% - 4.3rem);
}
.sclass{
  background-color: white !important;
}
</style>